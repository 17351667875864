import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green, grey } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { v4 as uuidv4 } from "uuid";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";

import AndroidIcon from "@material-ui/icons/Android";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TicketMessagesDialog from "../TicketMessagesDialog";

const useStyles = makeStyles(theme => ({
  ticket: {
    position: "relative"
  },

  pendingTicket: {
    cursor: "unset"
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4"
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px"
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    textAlign: "right"
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto"
  },

  contactLastMessage: {
    paddingRight: 20
  },

  newMessagesCount: {
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto"
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
    right: 20
  },

  acceptButton: {
    position: "absolute",
    right: "108px"
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%"
  },

  ticketInfo: {
    textAlign: "right"
  }
}));

const TicketListItemCustom = ({ canSeePhoto, ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [ticketUser, setTicketUser] = useState(null);
  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useContext(AuthContext);
  const { profile } = user;

  useEffect(() => {
    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user.name);
    }

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcepptTicket = async id => {
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleSelectTicket = ticket => {
    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
  };

  const renderTicketInfo = () => {
    if (ticketUser) {
      return (
        <>
          {profile === "admin" && (
            <Tooltip title="Espiar Conversa">
              <VisibilityIcon
                onClick={() => setOpenTicketMessageDialog(true)}
                fontSize="small"
                style={{
                  color: grey[700],
                  cursor: "pointer",
                  marginRight: 5
                }}
              />
            </Tooltip>
          )}
          {ticket.isBot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}
          {ticket.channel === "whatsapp" && (
            <Tooltip title={`Atribuido à ${ticketUser}`}>
              <WhatsAppIcon fontSize="small" style={{ color: grey[700] }} />
            </Tooltip>
          )}
          {ticket.channel === "instagram" && (
            <Tooltip title={`Atribuido à ${ticketUser}`}>
              <InstagramIcon fontSize="small" style={{ color: grey[700] }} />
            </Tooltip>
          )}
          {ticket.channel === "facebook" && (
            <Tooltip title={`Atribuido à ${ticketUser}`}>
              <FacebookIcon fontSize="small" style={{ color: grey[700] }} />
            </Tooltip>
          )}
        </>
      );
    } else {
      return (
        <>
          {ticket.isBot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}
          {profile === "admin" && (
            <Tooltip title="Espiar Conversa">
              <VisibilityIcon
                onClick={() => setOpenTicketMessageDialog(true)}
                fontSize="small"
                style={{
                  color: grey[700],
                  cursor: "pointer",
                  marginRight: 5
                }}
              />
            </Tooltip>
          )}
        </>
      );
    }
  };

  const truncatedMessage =
    ticket.lastMessage.length > 100
      ? ticket.lastMessage.substring(0, 100) + "..."
      : ticket.lastMessage;

  return (
    <>
      <React.Fragment key={ticket.id}>
        <TicketMessagesDialog
          open={openTicketMessageDialog}
          handleClose={() => setOpenTicketMessageDialog(false)}
          ticketId={ticket.id}
        ></TicketMessagesDialog>
        <ListItem
          dense
          button
          onClick={e => {
            if (ticket.status === "pending") return;
            handleSelectTicket(ticket);
          }}
          selected={ticketId && +ticketId === ticket.id}
          className={clsx(classes.ticket, {
            [classes.pendingTicket]: ticket.status === "pending"
          })}
        >
          <Tooltip
            arrow
            placement="right"
            title={ticket.queue?.name || "Sem fila"}
          >
            <span
              style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
              className={classes.ticketQueueColor}
            ></span>
          </Tooltip>
          <ListItemAvatar>
            {canSeePhoto ? (
              <Avatar src={ticket.contact.profilePicUrl} />
            ) : (
              <Avatar src="https://www.enactsoft.com/wp-content/uploads/2023/04/user-1.png" />
            )}
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={
              <span className={classes.contactNameWrapper}>
                <Typography
                  noWrap
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {ticket.contact.name}
                </Typography>
              </span>
            }
            secondary={
              <span className={classes.contactNameWrapper}>
                <Typography
                  className={classes.contactLastMessage}
                  noWrap
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {ticket.lastMessage ? (
                    <MarkdownWrapper>{truncatedMessage}</MarkdownWrapper>
                  ) : (
                    <br />
                  )}
                </Typography>

                <Badge
                  className={classes.newMessagesCount}
                  badgeContent={ticket.unreadMessages}
                  classes={{
                    badge: classes.badgeStyle
                  }}
                />
              </span>
            }
          />
          {ticket.status === "pending" && (
            <ButtonWithSpinner
              color="primary"
              variant="contained"
              className={classes.acceptButton}
              size="small"
              loading={loading}
              onClick={e => handleAcepptTicket(ticket.id)}
            >
              {i18n.t("ticketsList.buttons.accept")}
            </ButtonWithSpinner>
          )}
          <ListItemSecondaryAction>
            {ticket.status === "closed" && (
              <Badge
                className={classes.closedBadge}
                badgeContent={"closed"}
                color="primary"
              />
            )}
            {ticket.lastMessage && (
              <>
                <Typography
                  className={classes.lastMessageTime}
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                    <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                  ) : (
                    <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                  )}
                </Typography>
                <br />
                <Box className={classes.ticketInfo}>{renderTicketInfo()}</Box>
              </>
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <Divider variant="inset" component="li" />
      </React.Fragment>
    </>
  );
};

export default TicketListItemCustom;
