import React from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
	return (
		<Routes />
	);
};

export default App;
