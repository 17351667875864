import React, { useContext, useEffect, useState } from "react";

import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";


import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  colorAdorment: {
    width: 20,
    height: 20
  }
}));

const TagSchema = Yup.object().shape({
  name: Yup.string().min(3, "Mensagem muito curta").required("Obrigatório")
});

const BlackListModal = ({ open, onClose, tagId, reload }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const initialState = {
    name: ""
  };

  const [tag, setTag] = useState(initialState);

  useEffect(() => {
    try {
      (async () => {
        if (!tagId) return;

        const { data } = await api.get(`/blackLists/${tagId}`);
        setTag(prevState => {
          return { ...prevState, ...data };
        });
      })();
    } catch (err) {
      toastError(err);
    }
  }, [tagId, open]);

  const handleClose = () => {
    setTag(initialState);
    onClose();
  };

  const handleSaveTag = async values => {
    const tagData = { ...values, userId: user.id };
    try {
      if (tagId) {
        await api.put(`/blackLists/${tagId}`, tagData);
      } else {
        await api.post("/blackLists", tagData);
      }
      toast.success(i18n.t("blackListModal.success"));
      if (typeof reload == "function") {
        reload();
      }
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <h1 className="p-4 text-base font-semibold leading-6 text-gray-900" id="form-dialog-title">
          {tagId ? "Atualizar Tag de Bloqueio" : "Nova Tag de Bloqueio"}
        </h1>
        <Formik
          initialValues={tag}
          enableReinitialize={true}
          validationSchema={TagSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveTag(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
             <Form>
             <DialogContent dividers>
               <div className="flex flex-wrap mb-4">
                 <div className="w-full p-2">
                   <div className="relative">
                     <label
                       htmlFor="name"
                       className="block text-sm font-medium text-gray-700"
                     >
                       Nome
                     </label>
                     <Field name="name">
                       {({ field }) => (
                         <input
                           {...field}
                           autoFocus
                           type="text"
                           className="w-full p-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                         />
                       )}
                     </Field>
                   </div>
                 </div>
               </div>
             </DialogContent>
             <DialogActions>
               <button
                 onClick={handleClose}
                 type="button"
                 className="block rounded-md bg-red-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
               >
                 Cancelar
               </button>
               <button
                 type="submit"
                 className="block rounded-md bg-blue-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
               >
                 {tagId
                   ? `${i18n.t("tagModal.buttons.okEdit")}`
                   : `${i18n.t("tagModal.buttons.okAdd")}`}
               </button>
             </DialogActions>
           </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default BlackListModal;
