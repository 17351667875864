import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { isArray } from "lodash";
import { AuthContext } from "../context/Auth/AuthContext";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import toastError from "../errors/toastError";
import api from "../services/api";
import { socketConnection } from "../services/socket";
import logo from "../assets/logo-making.svg";

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach(chat => {
        const chatIndex = state.findIndex(u => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex(u => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex(u => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map(chat => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = props => {
  const [isSubmenuVisible, setSubmenuVisible] = useState(false);
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showTicket, setShowTicket] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showSheduled, setShowSheduled] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showBlockList, setShowBlockList] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showUser, setShowUsers] = useState(false);
  const [showQueu, setShowQueu] = useState(false);
  const [showDash, setShowDash] = useState(false);
  const [connections, setConnections] = useState(false);
  const [quickMessages, setQuickMessages] = useState(false);
  const [helps, setHelps] = useState(false);
  const [messagesApi, setMessagesApi] = useState(false);
  const [settings, setSettings] = useState(false);
  const [financeiro, setFinanceiro] = useState(false);
  const [announcements, setAnnouncements] = useState(false);
  const [subscription, setSubscription] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat`, data => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
    if (localStorage.getItem("operationshow")) {
      setShowTicket(true);
      setShowContact(true);
      setShowSheduled(true);
      setShowChat(true);
    }
    if (localStorage.getItem("supervisorshow")) {
      setShowTicket(true);
      setShowContact(true);
      setShowSheduled(true);
      setShowChat(true);
      setShowBlockList(true);
      setShowTags(true);
      setShowDash(true);
    }
    if (localStorage.getItem("managershow")) {
      setShowTicket(true);
      setShowContact(true);
      setShowSheduled(true);
      setShowChat(true);
      setShowBlockList(true);
      setShowTags(true);
      setShowUsers(true);
      setShowQueu(true);
      setShowDash(true);
    }
    if (localStorage.getItem("admShow")) {
      setShowTicket(true);
      setShowContact(true);
      setShowSheduled(true);
      setShowChat(true);
      setShowBlockList(true);
      setShowTags(true);
      setShowUsers(true);
      setShowQueu(true);
      setConnections(false);
      setQuickMessages(true);
      setHelps(true);
      setMessagesApi(true);
      setSettings(true);
      setFinanceiro(false);
      setAnnouncements(true);
      setSubscription(false);
      setShowDash(true);
    }

    if (localStorage.getItem("adminshow")) {
      setShowTicket(true);
      setShowContact(true);
      setShowSheduled(true);
      setShowChat(true);
      setShowBlockList(true);
      setShowTags(true);
      setShowUsers(true);
      setShowQueu(true);
      setConnections(true);
      setQuickMessages(true);
      setHelps(true);
      setMessagesApi(true);
      setSettings(true);
      setFinanceiro(true);
      setAnnouncements(true);
      setSubscription(true);
      setShowDash(true);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter(whats => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber }
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <>
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
          <div className="flex h-4 shrink-0 mt-4">
            <img src={logo} className="h-4" alt="Making Publicidade" />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {showDash && (
                    <li>
                      <RouterLink
                        onClick={() => setSubmenuVisible(false)}
                        to="/"
                        className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        <svg
                          className="h-6 w-6 shrink-0"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                          />
                        </svg>
                        Dashboard
                      </RouterLink>
                    </li>
                  )}
                  <li>
                    <RouterLink
                      onClick={() => setSubmenuVisible(false)}
                      to="/tickets"
                      className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                        />
                      </svg>
                      Atendimentos
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      onClick={() => setSubmenuVisible(false)}
                      to="/schedules"
                      className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                        />
                      </svg>
                      Agendamentos
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      onClick={() => setSubmenuVisible(false)}
                      to="/contacts"
                      className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                        />
                      </svg>
                      Contatos
                    </RouterLink>
                  </li>
                  <li>
                    <RouterLink
                      onClick={() => setSubmenuVisible(false)}
                      to="/quick-messages"
                      className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                        />
                      </svg>
                      Respostas Rápidas
                    </RouterLink>
                  </li>
                  {showCampaigns && (
                    <li>
                      <RouterLink
                        to="#"
                        className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        onClick={() => setSubmenuVisible(!isSubmenuVisible)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                          />
                        </svg>
                        Campanhas
                      </RouterLink>
                      {isSubmenuVisible && (
                        <ul>
                          <li>
                            <RouterLink
                              className="ml-10 text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              to="/campaigns"
                            >
                              Detalhes
                            </RouterLink>
                          </li>
                          <li>
                            <RouterLink
                              className="ml-10 text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              to="/contact-lists"
                            >
                              Contatos
                            </RouterLink>
                          </li>
                          <li>
                            <RouterLink
                              className="ml-10 text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              to="/campaigns-config"
                            >
                              Configurações
                            </RouterLink>
                          </li>
                        </ul>
                      )}
                    </li>
                  )}
                </ul>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-900">
                  Para seu time
                </div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {showTags && (
                    <li>
                      <RouterLink
                        onClick={() => setSubmenuVisible(false)}
                        to="/tags"
                        className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-white">
                          T
                        </span>
                        <span className="truncate">Tags</span>
                      </RouterLink>
                    </li>
                  )}
                  {showBlockList && (
                    <li>
                      <RouterLink
                        onClick={() => setSubmenuVisible(false)}
                        to="/blacklist"
                        className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-white">
                          B
                        </span>
                        <span className="truncate">Palavras Bloqueadas</span>
                      </RouterLink>
                    </li>
                  )}
                </ul>
              </li>
              <li>
                {(connections ||
                  showQueu ||
                  showUser ||
                  messagesApi ||
                  financeiro) && (
                  <div className="text-xs font-semibold leading-6 text-gray-900">
                    Administração
                  </div>
                )}
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {connections && (
                    <li>
                      <RouterLink
                        onClick={() => setSubmenuVisible(false)}
                        to="/connections"
                        className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-white">
                          C
                        </span>
                        <span className="truncate">Conexões</span>
                      </RouterLink>
                    </li>
                  )}
                  {showQueu && (
                    <li>
                      <RouterLink
                        onClick={() => setSubmenuVisible(false)}
                        to="/queues"
                        className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-white">
                          D
                        </span>
                        <span className="truncate">Departamentos</span>
                      </RouterLink>
                    </li>
                  )}
                  {showUser && (
                    <li>
                      <RouterLink
                        onClick={() => setSubmenuVisible(false)}
                        to="/users"
                        className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-white">
                          U
                        </span>
                        <span className="truncate">Usuários</span>
                      </RouterLink>
                    </li>
                  )}
                  {messagesApi && (
                    <li>
                      <RouterLink
                        onClick={() => setSubmenuVisible(false)}
                        to="/messages-api"
                        className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-white">
                          A
                        </span>
                        <span className="truncate">API</span>
                      </RouterLink>
                    </li>
                  )}
                  {financeiro && (
                    <li>
                      <RouterLink
                        onClick={() => setSubmenuVisible(false)}
                        to="/financeiro"
                        className="text-gray-900 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-white">
                          F
                        </span>
                        <span className="truncate">Financeiro</span>
                      </RouterLink>
                    </li>
                  )}
                </ul>
              </li>
              {settings && (
                <li className="mt-auto">
                  <RouterLink
                    onClick={() => setSubmenuVisible(false)}
                    to="/settings"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-900"
                  >
                    <svg
                      className="h-6 w-6 shrink-0"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    Configurações
                  </RouterLink>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default MainListItems;
