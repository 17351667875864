import React, { useEffect, useState } from "react";

import useSettings from "../../hooks/useSettings";
import { toast } from "react-toastify";

//import 'react-toastify/dist/ReactToastify.css';

export default function Options(props) {
  const {
    settings,
    scheduleTypeChanged,
    downloadChanged,
    uploadChanged,
    audioChanged,
    apagarMensagemChanged,
    fotoPerfilChanged,
    cpfChanged,
    cnpjChanged,
    cartaChanged
  } = props;
  const [userRating, setUserRating] = useState("disabled");
  const [scheduleType, setScheduleType] = useState("disabled");
  const [download, setDownload] = useState("enabled");
  const [fotoPerfil, setFotoPerfil] = useState("enabled");
  const [upload, setUpload] = useState("enabled");
  const [audio, setAudio] = useState("enabled");
  const [apagarMensagem, setApagarMensagem] = useState("enabled");
  const [cnpj, setCnpj] = useState("enabled");
  const [cpf, setCpf] = useState("enabled");
  const [carta, setCarta] = useState("enabled");
  const [callType, setCallType] = useState("enabled");
  const [chatbotType, setChatbotType] = useState("");
  const [CheckMsgIsGroup, setCheckMsgIsGroupType] = useState("enabled");

  const [loadingUserRating, setLoadingUserRating] = useState(false);
  const [loadingScheduleType, setLoadingScheduleType] = useState(false);
  const [loadingDownload, setloadingDownload] = useState(false);
  const [loadingFotoPerfil, setloadingFotoPerfil] = useState(false);
  const [loadingUpload, setloadingUpload] = useState(false);
  const [loadingAudio, setloadingAudio] = useState(false);
  const [loadingApagarMensagem, setloadingApagarMensagem] = useState(false);
  const [loadingCpf, setLoadingCpf] = useState(false);
  const [loadingCnpj, setLoadingCnpj] = useState(false);
  const [loadingCarta, setLoadingCarta] = useState(false);
  const [loadingCallType, setLoadingCallType] = useState(false);
  const [loadingChatbotType, setLoadingChatbotType] = useState(false);
  const [loadingCheckMsgIsGroup, setCheckMsgIsGroup] = useState(false);

  const [ipixcType, setIpIxcType] = useState("");
  const [loadingIpIxcType, setLoadingIpIxcType] = useState(false);
  const [tokenixcType, setTokenIxcType] = useState("");
  const [loadingTokenIxcType, setLoadingTokenIxcType] = useState(false);

  const [ipmkauthType, setIpMkauthType] = useState("");
  const [loadingIpMkauthType, setLoadingIpMkauthType] = useState(false);
  const [clientidmkauthType, setClientIdMkauthType] = useState("");
  const [loadingClientIdMkauthType, setLoadingClientIdMkauthType] =
    useState(false);
  const [clientsecretmkauthType, setClientSecrectMkauthType] = useState("");
  const [loadingClientSecrectMkauthType, setLoadingClientSecrectMkauthType] =
    useState(false);

  const [asaasType, setAsaasType] = useState("");
  const [loadingAsaasType, setLoadingAsaasType] = useState(false);

  const { update } = useSettings();

  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      const userRating = settings.find(s => s.key === "userRating");
      if (userRating) {
        setUserRating(userRating.value);
      }
      const scheduleType = settings.find(s => s.key === "scheduleType");
      if (scheduleType) {
        setScheduleType(scheduleType.value);
      }
      const download = settings.find(s => s.key === "download");
      if (download) {
        setDownload(download.value);
      }
      const fotoPerfil = settings.find(s => s.key === "fotoPerfil");
      if (fotoPerfil) {
        setFotoPerfil(fotoPerfil.value);
      }
      const upload = settings.find(s => s.key === "upload");
      if (upload) {
        setUpload(upload.value);
      }
      const audio = settings.find(s => s.key === "audio");
      if (audio) {
        setAudio(audio.value);
      }
      const apagarMensagem = settings.find(s => s.key === "apagarMensagem");
      if (apagarMensagem) {
        setApagarMensagem(apagarMensagem.value);
      }
      const cpf = settings.find(s => s.key === "cpf");
      if (cpf) {
        setCpf(cpf.value);
      }
      const cnpj = settings.find(s => s.key === "cnpj");
      if (cnpj) {
        setCnpj(cnpj.value);
      }
      const carta = settings.find(s => s.key === "carta");
      if (carta) {
        setUpload(carta.value);
      }
      const callType = settings.find(s => s.key === "call");
      if (callType) {
        setCallType(callType.value);
      }
      const CheckMsgIsGroup = settings.find(s => s.key === "CheckMsgIsGroup");
      if (CheckMsgIsGroup) {
        setCheckMsgIsGroupType(CheckMsgIsGroup.value);
      }
      const chatbotType = settings.find(s => s.key === "chatBotType");
      if (chatbotType) {
        setChatbotType(chatbotType.value);
      }

      const ipixcType = settings.find(s => s.key === "ipixc");
      if (ipixcType) {
        setIpIxcType(ipixcType.value);
      }

      const tokenixcType = settings.find(s => s.key === "tokenixc");
      if (tokenixcType) {
        setTokenIxcType(tokenixcType.value);
      }

      const ipmkauthType = settings.find(s => s.key === "ipmkauth");
      if (ipmkauthType) {
        setIpMkauthType(ipmkauthType.value);
      }

      const clientidmkauthType = settings.find(s => s.key === "clientidmkauth");
      if (clientidmkauthType) {
        setClientIdMkauthType(clientidmkauthType.value);
      }

      const clientsecretmkauthType = settings.find(
        s => s.key === "clientsecretmkauth"
      );
      if (clientsecretmkauthType) {
        setClientSecrectMkauthType(clientsecretmkauthType.value);
      }

      const asaasType = settings.find(s => s.key === "asaas");
      if (asaasType) {
        setAsaasType(asaasType.value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  async function handleChangeUserRating(value) {
    setUserRating(value);
    setLoadingUserRating(true);
    await update({
      key: "userRating",
      value
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingUserRating(false);
  }

  async function handleScheduleType(value) {
    setScheduleType(value);
    setLoadingScheduleType(true);
    await update({
      key: "scheduleType",
      value
    });
    //toast.success("Oraçãpeo atualizada com sucesso.");
    toast.success("Operação atualizada com sucesso.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light"
    });
    setLoadingScheduleType(false);
    if (typeof scheduleTypeChanged === "function") {
      scheduleTypeChanged(value);
    }
  }

  async function handleDownload(value) {
    setDownload(value);
    setloadingDownload(true);
    await update({
      key: "download",
      value
    });
    //toast.success("Oraçãpeo atualizada com sucesso.");
    toast.success("Operação atualizada com sucesso.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light"
    });
    setloadingDownload(false);
    if (typeof downloadChanged === "function") {
      downloadChanged(value);
    }
  }
  async function handleFotoPerfil(value) {
    setFotoPerfil(value);
    setloadingFotoPerfil(true);
    await update({
      key: "fotoPerfil",
      value
    });
    //toast.success("Oraçãpeo atualizada com sucesso.");
    toast.success("Operação atualizada com sucesso.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light"
    });
    setloadingFotoPerfil(false);
    if (typeof fotoPerfilChanged === "function") {
      fotoPerfilChanged(value);
    }
  }

  async function handleUpload(value) {
    setUpload(value);
    setloadingUpload(true);
    await update({
      key: "upload",
      value
    });
    //toast.success("Oraçãpeo atualizada com sucesso.");
    toast.success("Operação atualizada com sucesso.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light"
    });
    setloadingUpload(false);
    if (typeof uploadChanged === "function") {
      uploadChanged(value);
    }
  }

  async function handleAudio(value) {
    setAudio(value);
    setloadingAudio(true);
    await update({
      key: "audio",
      value
    });
    //toast.success("Oraçãpeo atualizada com sucesso.");
    toast.success("Operação atualizada com sucesso.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light"
    });
    setloadingAudio(false);
    if (typeof audioChanged === "function") {
      audioChanged(value);
    }
  }

  async function handleApagarMensagem(value) {
    setApagarMensagem(value);
    setloadingApagarMensagem(true);
    await update({
      key: "apagarMensagem",
      value
    });
    //toast.success("Oraçãpeo atualizada com sucesso.");
    toast.success("Operação atualizada com sucesso.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light"
    });
    setloadingApagarMensagem(false);
    if (typeof apagarMensagemChangedChanged === "function") {
      apagarMensagemChanged(value);
    }
  }

  async function handleCpf(value) {
    setCpf(value);
    setLoadingCpf(true);
    await update({
      key: "cpf",
      value
    });
    //toast.success("Oraçãpeo atualizada com sucesso.");
    toast.success("Operação atualizada com sucesso.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light"
    });
    setLoadingCpf(false);
    if (typeof cpfChanged === "function") {
      cpfChanged(value);
    }
  }

  async function handleCnpj(value) {
    setCnpj(value);
    setLoadingCnpj(true);
    await update({
      key: "cnpj",
      value
    });
    //toast.success("Oraçãpeo atualizada com sucesso.");
    toast.success("Operação atualizada com sucesso.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light"
    });
    setLoadingCnpj(false);
    if (typeof cnpjChanged === "function") {
      cnpjChanged(value);
    }
  }

  async function handleCarta(value) {
    setCarta(value);
    setLoadingCarta(true);
    await update({
      key: "carta",
      value
    });
    //toast.success("Oraçãpeo atualizada com sucesso.");
    toast.success("Operação atualizada com sucesso.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light"
    });
    setLoadingCarta(false);
    if (typeof cartaChanged === "function") {
      cartaChanged(value);
    }
  }

  async function handleCallType(value) {
    setCallType(value);
    setLoadingCallType(true);
    await update({
      key: "call",
      value
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingCallType(false);
  }

  async function handleChatbotType(value) {
    setChatbotType(value);
    setLoadingChatbotType(true);
    await update({
      key: "chatBotType",
      value
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingChatbotType(false);
  }

  async function handleGroupType(value) {
    setCheckMsgIsGroupType(value);
    setCheckMsgIsGroup(true);
    await update({
      key: "CheckMsgIsGroup",
      value
    });
    toast.success("Operação atualizada com sucesso.");
    setCheckMsgIsGroup(false);
  }

  async function handleChangeIPIxc(value) {
    setIpIxcType(value);
    setLoadingIpIxcType(true);
    await update({
      key: "ipixc",
      value
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingIpIxcType(false);
  }

  async function handleChangeTokenIxc(value) {
    setTokenIxcType(value);
    setLoadingTokenIxcType(true);
    await update({
      key: "tokenixc",
      value
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingTokenIxcType(false);
  }

  async function handleChangeIpMkauth(value) {
    setIpMkauthType(value);
    setLoadingIpMkauthType(true);
    await update({
      key: "ipmkauth",
      value
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingIpMkauthType(false);
  }

  async function handleChangeClientIdMkauth(value) {
    setClientIdMkauthType(value);
    setLoadingClientIdMkauthType(true);
    await update({
      key: "clientidmkauth",
      value
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingClientIdMkauthType(false);
  }

  async function handleChangeClientSecrectMkauth(value) {
    setClientSecrectMkauthType(value);
    setLoadingClientSecrectMkauthType(true);
    await update({
      key: "clientsecretmkauth",
      value
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingClientSecrectMkauthType(false);
  }

  async function handleChangeAsaas(value) {
    setAsaasType(value);
    setLoadingAsaasType(true);
    await update({
      key: "asaas",
      value
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingAsaasType(false);
  }
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="ratings"
              className="block text-sm font-medium text-gray-700"
            >
              Avaliações
            </label>
            <select
              id="ratings"
              value={userRating}
              onChange={async e => {
                handleChangeUserRating(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"disabled"}>Desabilitadas</option>
              <option value={"enabled"}>Habilitadas</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingUserRating && "Atualizando..."}
            </div>
          </div>
        </div>

        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="schedule-type"
              className="block text-sm font-medium text-gray-700"
            >
              Gerenciamento de Expediente
            </label>
            <select
              id="schedule-type"
              value={scheduleType}
              onChange={async e => {
                handleScheduleType(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"disabled"}>Desabilitado</option>
              <option value={"queue"}>Gerenciamento Por Fila</option>
              <option value={"company"}>Gerenciamento Por Empresa</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingScheduleType && "Atualizando..."}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="downloads"
              className="block text-sm font-medium text-gray-700"
            >
              Permitir Downloads
            </label>
            <select
              id="downloads"
              value={download}
              onChange={async e => {
                handleDownload(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"disabled"}>Desabilitado</option>
              <option value={"enabled"}>Habilitado</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingDownload && "Atualizando..."}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="profilePicture"
              className="block text-sm font-medium text-gray-700"
            >
              Foto de perfil?
            </label>
            <select
              id="profilePicture"
              value={fotoPerfil}
              onChange={async e => {
                handleFotoPerfil(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"disabled"}>Desabilitado</option>
              <option value={"enabled"}>Habilitado</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingFotoPerfil && "Atualizando..."}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="uploads"
              className="block text-sm font-medium text-gray-700"
            >
              Permitir Uploads
            </label>
            <select
              id="uploads"
              value={upload}
              onChange={async e => {
                handleUpload(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"disabled"}>Desabilitado</option>
              <option value={"enabled"}>Habilitado</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingUpload && "Atualizando..."}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="audio"
              className="block text-sm font-medium text-gray-700"
            >
              Permitir Áudio
            </label>
            <select
              id="audio"
              value={audio}
              onChange={async e => {
                handleAudio(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"disabled"}>Desabilitado</option>
              <option value={"enabled"}>Habilitado</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingAudio && "Atualizando..."}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="deleteMessage"
              className="block text-sm font-medium text-gray-700"
            >
              Permitir Apagar Mensagem
            </label>
            <select
              id="deleteMessage"
              value={apagarMensagem}
              onChange={async e => {
                handleApagarMensagem(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"disabled"}>Desabilitado</option>
              <option value={"enabled"}>Habilitado</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingApagarMensagem && "Atualizando..."}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="blockCpf"
              className="block text-sm font-medium text-gray-700"
            >
              Bloquear CPF
            </label>
            <select
              id="blockCpf"
              value={cpf}
              onChange={async e => {
                handleCpf(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"disabled"}>Desabilitado</option>
              <option value={"enabled"}>Habilitado</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingCpf && "Atualizando..."}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="blockCnpj"
              className="block text-sm font-medium text-gray-700"
            >
              Bloquear CNPJ
            </label>
            <select
              id="blockCnpj"
              value={cnpj}
              onChange={async e => {
                handleCnpj(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"disabled"}>Desabilitado</option>
              <option value={"enabled"}>Habilitado</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingCnpj && "Atualizando..."}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="blockCreditCard"
              className="block text-sm font-medium text-gray-700"
            >
              Bloquear Cartão de Crédito
            </label>
            <select
              id="blockCreditCard"
              value={carta}
              onChange={async e => {
                handleCarta(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"disabled"}>Desabilitado</option>
              <option value={"enabled"}>Habilitado</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingCnpj && "Atualizando..."}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="ignoreGroupMessages"
              className="block text-sm font-medium text-gray-700"
            >
              Ignorar Mensagens de Grupos
            </label>
            <select
              id="ignoreGroupMessages"
              value={CheckMsgIsGroup}
              onChange={async e => {
                handleGroupType(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"disabled"}>Desabilitado</option>
              <option value={"enabled"}>Habilitado</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingScheduleType && "Atualizando..."}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="acceptCall"
              className="block text-sm font-medium text-gray-700"
            >
              Aceitar Chamada
            </label>
            <select
              id="acceptCall"
              value={callType}
              onChange={async e => {
                handleCallType(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"disabled"}>Não Aceitar</option>
              <option value={"enabled"}>Aceitar</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingCallType && "Atualizando..."}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-1/2 md:w-1/3 p-2">
          <div className="relative">
            <label
              htmlFor="chatbotType"
              className="block text-sm font-medium text-gray-700"
            >
              Tipo Chatbot
            </label>
            <select
              id="chatbotType"
              value={chatbotType}
              onChange={async e => {
                handleChatbotType(e.target.value);
              }}
              className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={"text"}>Texto</option>
              <option value={"button"}>Botão</option>
              <option value={"list"}>Lista</option>
            </select>
            <div className="mt-2 text-sm text-gray-500">
              {loadingChatbotType && "Atualizando..."}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
