import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogActions,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required")
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();
  const initialState = {
    name: "",
    greetingMessage: "",
    complationMessage: "",
    outOfHoursMessage: "",
    ratingMessage: "",
    isDefault: false,
    token: "",
    provider: "beta"
  };
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}?session=0`);
        setWhatsApp(data);

        const whatsQueueIds = data.queues?.map(queue => queue.id);
        setSelectedQueueIds(whatsQueueIds);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  const handleSaveWhatsApp = async values => {
    const queueIds = values.isDefault ? [] : selectedQueueIds;

    const whatsappData = { ...values, queueIds };
    delete whatsappData["queues"];
    delete whatsappData["session"];

    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        await api.post("/whatsapp", whatsappData);
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <h1 className="p-4 text-base font-semibold leading-6 text-gray-900" id="form-dialog-title">
          {whatsAppId
            ? i18n.t("whatsappModal.title.edit")
            : i18n.t("whatsappModal.title.add")}
        </h1>
        <Formik
          initialValues={whatsApp}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsApp(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className="flex flex-wrap mb-4">
                  <div className="w-full p-2 sm:w-1/2 md:w-2/3">
                    <div className="relative">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nome
                      </label>
                      <Field name="name">
                        {({ field }) => (
                          <input
                            {...field}
                            autoFocus
                            type="text"
                            className="w-full p-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="w-full p-2 sm:w-1/2 md:w-1/3">
                    <div className="relative">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Conexão padrão?
                      </label>
                      <FormControlLabel
                        control={
                          <Field
                            as={Switch}
                            color="primary"
                            name="isDefault"
                            checked={values.isDefault}
                          />
                        }
                        label={i18n.t("whatsappModal.form.default")}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full p-2">
                  <div className="relative">
                    <label
                      htmlFor="greetingMessage"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {i18n.t("queueModal.form.greetingMessage")}
                    </label>
                    <Field name="greetingMessage">
                      {({ field }) => (
                        <textarea
                          {...field}
                          rows={2}
                          id="greetingMessage"
                          className={`w-full p-5 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md 
            ${
              touched.greetingMessage && errors.greetingMessage
                ? "border-red-500"
                : ""
            }`}
                        />
                      )}
                    </Field>
                    {touched.greetingMessage && errors.greetingMessage && (
                      <p className="text-red-500 text-xs italic">
                        {errors.greetingMessage}
                      </p>
                    )}
                  </div>
                </div>

                <div className="w-full p-2">
                  <div className="relative">
                    <label
                      htmlFor="complationMessage"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {i18n.t("queueModal.form.complationMessage")}
                    </label>
                    <Field name="complationMessage">
                      {({ field }) => (
                        <textarea
                          {...field}
                          rows={2}
                          id="complationMessage"
                          className={`w-full p-5 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md 
            ${
              touched.complationMessage && errors.complationMessage
                ? "border-red-500"
                : ""
            }`}
                        />
                      )}
                    </Field>
                    {touched.complationMessage && errors.complationMessage && (
                      <p className="text-red-500 text-xs italic">
                        {errors.complationMessage}
                      </p>
                    )}
                  </div>
                </div>

                <div className="w-full p-2">
                  <div className="relative">
                    <label
                      htmlFor="outOfHoursMessage"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {i18n.t("queueModal.form.outOfHoursMessage")}
                    </label>
                    <Field name="outOfHoursMessage">
                      {({ field }) => (
                        <textarea
                          {...field}
                          rows={2}
                          id="outOfHoursMessage"
                          className={`w-full p-5 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md 
            ${
              touched.outOfHoursMessage && errors.outOfHoursMessage
                ? "border-red-500"
                : ""
            }`}
                        />
                      )}
                    </Field>
                    {touched.outOfHoursMessage && errors.outOfHoursMessage && (
                      <p className="text-red-500 text-xs italic">
                        {errors.outOfHoursMessage}
                      </p>
                    )}
                  </div>
                </div>

                <div className="w-full p-2">
                  <div className="relative">
                    <label
                      htmlFor="ratingMessage"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {i18n.t("queueModal.form.ratingMessage")}
                    </label>
                    <Field name="ratingMessage">
                      {({ field }) => (
                        <textarea
                          {...field}
                          rows={2}
                          id="ratingMessage"
                          className={`w-full p-5 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md 
            ${
              touched.ratingMessage && errors.ratingMessage
                ? "border-red-500"
                : ""
            }`}
                        />
                      )}
                    </Field>
                    {touched.ratingMessage && errors.ratingMessage && (
                      <p className="text-red-500 text-xs italic">
                        {errors.ratingMessage}
                      </p>
                    )}
                  </div>
                </div>
                {!values.isDefault && (
                  <QueueSelect
                    selectedQueueId={selectedQueueIds}
                    onChange={selectedIds => setSelectedQueueIds(selectedIds)}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <button
                  onClick={handleClose}
                  type="button"
                  className="block rounded-md bg-red-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="block rounded-md bg-blue-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  {whatsAppId
                    ? `${i18n.t("userModal.buttons.okEdit")}`
                    : `${i18n.t("userModal.buttons.okAdd")}`}
                </button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
