import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";

const DepartamentChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data && data.length > 0) {
      renderChart();
    }
  }, [data]);

  const renderChart = () => {
    if (!chartRef.current) {
      return;
    }

    // Remover o gráfico anterior
    ApexCharts.exec(chartRef.current.id, "destroy");

    if (!data || data.length === 0) {
      // Exibir mensagem de que não há dados
      chartRef.current.innerText = "Nenhum dado encontrado.";
      return;
    }

    const options = {
      chart: {
        type: "bar",
        height: "100%"
      },
      series: [
        {
          name: "Total Atendimentos",
          data: data.map(item => item.totalatendimentos)
        }
      ],
      xaxis: {
        categories: data.map(item => item.department)
      }
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();
  };

  return <div ref={chartRef} className="w-full h-64" />;
};

export default DepartamentChart;
