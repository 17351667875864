import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsListCustom";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { TagsFilter } from "../TagsFilter";
import { UsersFilter } from "../UsersFilter";

const useStyles = makeStyles(theme => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee"
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8
  },

  tab: {
    minWidth: 120,
    width: 120
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    padding: theme.spacing(1)
  },

  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1)
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center"
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30
  },

  badge: {
    right: "-10px"
  },
  show: {
    display: "block"
  },
  hide: {
    display: "none !important"
  }
}));

const TicketsManagerTabs = () => {
  const classes = useStyles();
  const history = useHistory();

  const [searchParam, setSearchParam] = useState("");
  const [sendScrool, setSendScrool] = useState(false);
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const { profile } = user;

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user.queues.map(q => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (user.profile !== "attendant") {
      setShowAllTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  let searchTimeout;

  const handleSearch = e => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabTail = newValue => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const handleChangeTabOpenTail = newValue => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = status => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleCloseOrOpenTicket = ticket => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleSelectedTags = selecteds => {
    const tags = selecteds.map(t => t.id);
    setSelectedTags(tags);
  };

  const handleSelectedChannels = selecteds => {
    const channels = selecteds.map(t => t.id);
    setSelectedChannels(channels);
  };

  const handleSelectedUsers = selecteds => {
    const users = selecteds.map(t => t.id);
    setSelectedUsers(users);
  };

  const scrolling = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      setSendScrool(true);
    } else {
      setSendScrool(false);
    }
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={ticket => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <div className="bg-white shadow-sm">
        <nav className="flex py-1.5">
          <button
            onClick={() => handleChangeTabTail("open")}
            className={`w-1/3 py-4 text-center text-dark-500 ${
              tab === "open" ? "border-b-2 border-dark" : ""
            }`}
          >
            {i18n.t("tickets.tabs.open.title")}
          </button>
          <button
            onClick={() => handleChangeTabTail("closed")}
            className={`w-1/3 py-4 text-center text-dark-500 ${
              tab === "closed" ? "border-b-2 border-dark" : ""
            }`}
          >
            {i18n.t("tickets.tabs.closed.title")}
          </button>
          <button
            onClick={() => handleChangeTabTail("search")}
            className={`w-1/3 py-4 text-center text-dark-500 ${
              tab === "search" ? "border-b-2 border-dark" : ""
            }`}
          >
            {i18n.t("tickets.tabs.search.title")}
          </button>
        </nav>
      </div>

      <div className="rounded bg-white shadow p-4">
        {tab === "search" ? (
          <div className="flex items-center space-x-2">
            <SearchIcon className="text-gray-400" />
            <input
              ref={searchInputRef}
              placeholder={i18n.t("tickets.search.placeholder")}
              type="search"
              onChange={handleSearch}
              className="flex-1 py-2 pl-3 pr-10 rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:bg-white"
            />
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <div className="flex space-x-4">
                <button
                  onClick={() => setNewTicketModalOpen(true)}
                  className="border border-indigo-500 text-indigo-500 hover:bg-indigo-500 hover:text-white transition duration-150 ease-in-out rounded px-4 py-2 focus:outline-none focus:ring focus:ring-indigo-200"
                >
                  {i18n.t("ticketsManager.buttons.newTicket")}
                </button>
                {user.profile !== "attendant" && (
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={showAllTickets}
                      onChange={() =>
                        setShowAllTickets(prevState => !prevState)
                      }
                      name="showAllTickets"
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                      {i18n.t("tickets.buttons.showAll")}
                    </span>
                  </label>
                )}
              </div>
              <TicketsQueueSelect
                className="w-1/2 ml-6"
                selectedQueueIds={selectedQueueIds}
                userQueues={user?.queues}
                onChange={values => setSelectedQueueIds(values)}
              />
            </div>
          </>
        )}
      </div>

      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        <div className="bg-gray-200">
          <div className="flex justify-center border-b border-gray-300">
            <button
              onClick={() => handleChangeTabOpenTail("open")}
              className={`py-2 px-4 text-gray-600 ${
                tabOpen === "open" ? "border-b-2 border-gray-600" : ""
              }`}
            >
              {i18n.t("ticketsList.assignedHeader")}
              {openCount > 0 && (
                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full bg-gray-600 text-xs font-medium text-white">
                  {openCount}
                </span>
              )}
            </button>
            <button
              onClick={() => handleChangeTabOpenTail("pending")}
              className={`py-2 px-4 text-gray-600 ${
                tabOpen === "pending" ? "border-b-2 border-gray-600" : ""
              }`}
            >
              {i18n.t("ticketsList.pendingHeader")}
              {pendingCount > 0 && (
                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full bg-gray-600 text-xs font-medium text-white">
                  {pendingCount}
                </span>
              )}
            </button>
          </div>
        </div>

        <div
          className="list-chat bg-white border rounded shadow"
          onScroll={scrolling}
        >
          <div className="overflow-y-auto" style={applyPanelStyle("open")}>
            <TicketsList
              status="open"
              showAll={showAllTickets}
              selectedQueueIds={selectedQueueIds}
              updateCount={val => setOpenCount(val)}
              scrolling={sendScrool}
            />
          </div>
          <div className="overflow-y-auto" style={applyPanelStyle("pending")}>
            <TicketsList
              status="pending"
              selectedQueueIds={selectedQueueIds}
              updateCount={val => setPendingCount(val)}
              scrolling={sendScrool}
            />
          </div>
        </div>
      </TabPanel>
      <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
        <TicketsList
          status="closed"
          showAll={true}
          selectedQueueIds={selectedQueueIds}
          scrolling={sendScrool}
        />
      </TabPanel>
      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        <TagsFilter onFiltered={handleSelectedTags} />
        {profile !== "attendant" && (
          <UsersFilter onFiltered={handleSelectedUsers} />
        )}
        <TicketsList
          searchParam={searchParam}
          showAll={true}
          tags={selectedTags}
          channels={selectedChannels}
          users={selectedUsers}
          selectedQueueIds={selectedQueueIds}
          scrolling={sendScrool}
        />
      </TabPanel>
    </Paper>
  );
};

export default TicketsManagerTabs;
