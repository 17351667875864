import React, { useState, useEffect, useContext } from "react";

import { Avatar, CardHeader } from "@material-ui/core";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick }) => {
  const { user } = useContext(AuthContext);
  const [userName, setUserName] = useState("");
  const [contactName, setContactName] = useState("");

  const [urlPerfil, setUrlPerfil] = useState(
    "https://www.enactsoft.com/wp-content/uploads/2023/04/user-1.png"
  );

  useEffect(() => {
    api.get(`/settings`).then(({ data }) => {
      if (Array.isArray(data)) {
        const fotoPerfil = data.find(d => d.key === "fotoPerfil");
        if (fotoPerfil) {
          if (fotoPerfil.value === "enabled") {
            setUrlPerfil(contact.profilePicUrl);
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (contact) {
      setContactName(contact.name);
      if (document.body.offsetWidth < 600) {
        if (contact.name.length > 10) {
          const truncadName = contact.name.substring(0, 10) + "...";
          setContactName(truncadName);
        }
      }
    }

    if (user && contact) {
      setUserName(`${i18n.t("messagesList.header.assignedTo")} ${user.name}`);

      if (document.body.offsetWidth < 600) {
        setUserName(`${user.name}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      onClick={onClick}
      className="flex items-center cursor-pointer p-3 border-b"
    >
      <img
        src={urlPerfil}
        alt="contact_image"
        className="rounded-full w-10 h-10 mr-4"
      />
      <div className="truncate">
        <div className="text-d truncate">{`${contactName} #${ticket.id}`}</div>
        {ticket.user && (
          <div className="text-sm text-gray-600 truncate">{userName}</div>
        )}
      </div>
    </div>
  );
};

export default TicketInfo;
