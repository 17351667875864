import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import TicketsManager from "../../components/TicketsManagerTabs/";
import Ticket from "../../components/Ticket/";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
  chatContainer: {
    flex: 1,
    // backgroundColor: "#eee",
    padding: theme.spacing(4),
    height: `calc(100% - 48px)`,
    overflowY: "hidden"
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%"
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden"
  },
  messagesWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column"
  },
  welcomeMsg: {
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center"
  }
}));

const TicketsCustom = () => {
  const classes = useStyles();
  const { ticketId } = useParams();

  return (
    <div className="mt-20 px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden  ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <div className="chat-container">
                <div className="chat-paper">
                  <div className="flex full-height">
                    <div className="w-1/3 contacts-wrapper">
                      <TicketsManager />
                    </div>
                    <div className="w-2/3 messages-wrapper">
                      {ticketId ? (
                        <>
                          <Ticket />
                        </>
                      ) : (
                        <div className="border p-4 welcome-msg">
                          <span>{i18n.t("chat.noTicketMessage")}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketsCustom;
