import React from "react";


const jsonData = {
  number: "558599999999",
  body: "Sua mensagem"
};

const jsonString = JSON.stringify(jsonData, null, 2);

const jsonDataMedia = {
  number: "558599999999",
  body: "Sua mensagem",
  medias: "arquivo"
};

const jsonStringMedia = JSON.stringify(jsonDataMedia, null, 2);

const jsonDataDash = {
  date_from: "2023-01-01",
  date_to: "2023-01-31",
  user: 1,
  connection: 2,
  tag: 3,
  queue: 4,
};

const jsonStringDash = JSON.stringify(jsonDataDash, null, 2);



const MessagesAPI = () => {
 
  return (
    <>
      <div className="mt-20 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              API
            </h1>
            <p className="mt-2 text-sm text-gray-700">Documentação da API</p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Detalhes
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Endpoint
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Método
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Headers
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Body
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        Mensagem de texto
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        /api/messages/send
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        POST
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        X_TOKEN (token cadastrado) <br /> Content-Type
                        (application/json)
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <pre className="whitespace-pre overflow-auto">
                          {jsonString}
                        </pre>
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        Mensagem com mídea
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        /api/messages/send
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        POST
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        X_TOKEN (token cadastrado) <br /> Content-Type
                        (application/json)
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <pre className="whitespace-pre overflow-auto">
                          {jsonStringMedia}
                        </pre>
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        Informações do Dashboard
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        /dashboard-info
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        POST
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        X_TOKEN (token cadastrado) <br /> Content-Type
                        (application/json)
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <pre className="whitespace-pre overflow-auto">
                          {jsonStringDash}
                        </pre>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessagesAPI;
