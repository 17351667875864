import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Grid, ListItemText, MenuItem, Select } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email")
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
  const history = useHistory();

  const classes = useStyles();
  const isMounted = useRef(true);

  const { user } = useContext(AuthContext);
  const [selectedQueue, setSelectedQueue] = useState(
    user.queues?.length > 0 ? user.queues[0].id : ""
  );

  const initialState = {
    name: "",
    number: "",
    email: "",
    queueId: ""
  };

  const [contact, setContact] = useState(initialState);

  const handleQueueChange = e => {
    setSelectedQueue(e.target.value);

    // Aqui você está atualizando o estado `contact` com o `queueId` selecionado.
    setContact(prevState => {
      return { ...prevState, queueId: e.target.value };
    });
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact(prevState => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contacts/${contactId}`);
        if (isMounted.current) {
          setContact(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [contactId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setContact(initialState);
  };

  const handleSaveContact = async values => {
    try {
      const payload = {
        ...values,
        queueId: selectedQueue // Aqui nós garantimos que o queueId correto seja enviado.
      };

      let newContactId;

      if (contactId) {
        await api.put(`/contacts/${contactId}`, payload);
        newContactId = contactId;
        handleClose();
      } else {
        const { data } = await api.post("/contacts", payload);
        if (onSave) {
          onSave(data);
        }
        newContactId = data.id; // Vamos capturar o ID do novo contato criado.
        handleClose();
      }

      try {
        const { data: ticket } = await api.post("/tickets", {
          contactId: newContactId,
          queueId: selectedQueue, // Aqui nós garantimos que o queueId correto seja enviado.
          userId: user?.id,
          status: "open"
        });
        history.push(`/tickets/${ticket.uuid}`);
      } catch (err) {
        toastError(err);
      }

      toast.success(i18n.t("contactModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <h1 className="p-4 text-base font-semibold leading-6 text-gray-900" id="form-dialog-title">
          {contactId
            ? `${i18n.t("contactModal.title.edit")}`
            : `${i18n.t("contactModal.title.add")}`}
        </h1>
        <Formik
          initialValues={contact}
          enableReinitialize={true}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveContact(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                {user.queues?.length > 0 ? (
                   <div className="flex flex-wrap mb-4">
                    <div className="w-full p-2">
                      <label
                        htmlFor="queueId"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Departamento
                      </label>
                      <Field name="queueId">
                        {({ field }) => (
                          <select
                            {...field}
                            className="custom-select w-full p-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            {user.queues.map((element, index) => {
                              if (element.value === "admin") {
                                return null; // Remove o item caso o perfil seja "admin"
                              }
                              return (
                                <option value={element.value} key={index}>
                                  {element.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </Field>
                    </div>

                    <div className="w-full p-2">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nome
                      </label>
                      <Field name="name">
                        {({ field }) => (
                          <input
                            {...field}
                            type="name"
                            className="w-full p-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          />
                        )}
                      </Field>
                    </div>

                    <div className="w-full p-2">
                      <label
                        htmlFor="number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Número
                      </label>
                      <Field name="number">
                        {({ field }) => (
                          <input
                            {...field}
                            type="number"
                            className="w-full p-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          />
                        )}
                      </Field>
                    </div>

                    <div className="w-full p-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        E-mail
                      </label>
                      <Field name="email">
                        {({ field }) => (
                          <input
                            {...field}
                            type="email"
                            className="w-full p-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                ) : (
                  <p>
                    Você precisa estar vinculado à uma fila para adicionar um
                    contatou ou enviar uma mensagem.
                  </p>
                )}
              </DialogContent>
              <DialogActions>
                <button
                  onClick={handleClose}
                  type="button"
                  className="block rounded-md bg-red-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="block rounded-md bg-blue-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  {contactId
                    ? `${i18n.t("contactModal.buttons.okEdit")}`
                    : `${i18n.t("contactModal.buttons.okAdd")}`}
                </button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ContactModal;
