import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Card, CardHeader } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(theme => ({
  ticketHeader: {
    display: "flex",
    backgroundColor: "#eee",
    flex: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  }
}));

const TicketHeaderSkeleton = () => {
  const classes = useStyles();

  return (
    <div className="bg-white border rounded">
      <div className="flex items-center p-4">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-gray-400 h-12 w-12"></div>
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-gray-400 rounded w-20"></div>
            <div className="h-4 bg-gray-400 rounded w-28"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketHeaderSkeleton;
