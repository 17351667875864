import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { i18n } from "../../translate/i18n";

const ConfirmationModal = ({ title, children, open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="confirm-dialog"
    >
      <h1 className="p-4 text-base font-semibold leading-6 text-gray-900" id="form-dialog-title">{title}</h1>
      <DialogContent dividers>
        <Typography>{children}</Typography>
      </DialogContent>
      <DialogActions>
        <button
          onClick={() => onClose(false)}
          type="button"
          className="block rounded-md bg-red-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          Cancelar
        </button>
        <button
		 onClick={() => {
            onClose(false);
            onConfirm();
          }}
          type="button"
          className="block rounded-md bg-blue-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          {i18n.t("confirmationModal.buttons.confirm")}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
