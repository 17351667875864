import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import api from "../../services/api";

import { i18n } from "../../translate/i18n";

import ConfirmationModal from "../../components/ConfirmationModal";

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles
  },
  textRight: {
    textAlign: "right"
  },
  tabPanelsContainer: {
    padding: theme.spacing(2)
  }
}));

const initialSettings = {
  messageInterval: 20,
  longerIntervalAfter: 20,
  greaterInterval: 60,
  variables: []
};

const CampaignsConfig = () => {
  const classes = useStyles();

  const [settings, setSettings] = useState(initialSettings);
  const [showVariablesForm, setShowVariablesForm] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [variable, setVariable] = useState({ key: "", value: "" });

  useEffect(() => {
    api.get("/campaign-settings").then(({ data }) => {
      const settingsList = [];
      if (Array.isArray(data) && data.length > 0) {
        data.forEach(item => {
          settingsList.push([item.key, JSON.parse(item.value)]);
        });
        setSettings(Object.fromEntries(settingsList));
      }
    });
  }, []);

  const handleOnChangeVariable = e => {
    if (e.target.value !== null) {
      const changedProp = {};
      changedProp[e.target.name] = e.target.value;
      setVariable(prev => ({ ...prev, ...changedProp }));
    }
  };

  const handleOnChangeSettings = e => {
    const changedProp = {};
    changedProp[e.target.name] = e.target.value;
    setSettings(prev => ({ ...prev, ...changedProp }));
  };

  const addVariable = () => {
    setSettings(prev => {
      const variablesExists = settings.variables.filter(
        v => v.key === variable.key
      );
      const variables = prev.variables;
      if (variablesExists.length === 0) {
        variables.push(Object.assign({}, variable));
        setVariable({ key: "", value: "" });
      }
      return { ...prev, variables };
    });
  };

  const removeVariable = () => {
    const newList = settings.variables.filter(v => v.key !== selectedKey);
    setSettings(prev => ({ ...prev, variables: newList }));
    setSelectedKey(null);
  };

  const saveSettings = async () => {
    await api.post("/campaign-settings", { settings });
    toast.success("Configurações salvas");
  };

  return (
    <>
      <div className="mt-20 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Configurações de Campanhas
            </h1>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <div className="w-full p-2">
                  <div className="space-y-4 grid grid-cols-1 md:grid-cols-3 gap-2">
                    <div className="w-full p-2 mt-4">
                      <label
                        htmlFor="messageInterval"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Intervalo Randômico de Disparo
                      </label>
                      <select
                        name="messageInterval"
                        id="messageInterval"
                        value={settings.messageInterval}
                        onChange={e => handleOnChangeSettings(e)}
                        className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option value={0}>Sem Intervalo</option>
                        <option value={5}>5 segundos</option>
                        <option value={10}>10 segundos</option>
                        <option value={15}>15 segundos</option>
                        <option value={20}>20 segundos</option>
                      </select>
                    </div>
                    <div className="w-full p-2">
                      <label
                        htmlFor="longerIntervalAfter"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Intervalo Maior Após
                      </label>
                      <select
                        name="longerIntervalAfter"
                        id="longerIntervalAfter"
                        value={settings.longerIntervalAfter}
                        onChange={e => handleOnChangeSettings(e)}
                        className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option value={0}>Não definido</option>
                        <option value={5}>5 mensagens</option>
                        <option value={10}>10 mensagens</option>
                        <option value={15}>15 mensagens</option>
                        <option value={20}>20 mensagens</option>
                      </select>
                    </div>
                    <div className="w-full p-2">
                      <label
                        htmlFor="greaterInterval"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Intervalo de Disparo Maior
                      </label>
                      <select
                        name="greaterInterval"
                        id="greaterInterval"
                        value={settings.greaterInterval}
                        onChange={e => handleOnChangeSettings(e)}
                        className="custom-select mt-1 block w-full pl-3 pr-10 py-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option value={0}>Sem Intervalo</option>
                        <option value={20}>20 segundos</option>
                        <option value={30}>30 segundos</option>
                        <option value={40}>40 segundos</option>
                        <option value={50}>50 segundos</option>
                        <option value={60}>60 segundos</option>
                      </select>
                    </div>
                    <div className="md:col-span-3 flex justify-end items-center space-x-2">
                      <button
                        onClick={saveSettings}
                        className="rounded-md bg-blue-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        Salvar Configurações
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={i18n.t("campaigns.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={removeVariable}
      >
        {i18n.t("campaigns.confirmationModal.deleteMessage")}
      </ConfirmationModal>
    </>
  );
};

export default CampaignsConfig;
