import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function QuickMessagesTable(props) {
  const { messages, showLoading, editMessage, deleteMessage } = props;
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (Array.isArray(messages)) {
      setRows(messages);
    }
    if (showLoading !== undefined) {
      setLoading(showLoading);
    }
  }, [messages, showLoading]);

  const handleEdit = message => {
    editMessage(message);
  };

  const handleDelete = message => {
    deleteMessage(message);
  };

  const renderRows = () => {
    return (
      <>
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Atalho
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Mensagem
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {rows.map(message => (
              <tr key={message.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {message.shortcode}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {message.message}
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <button
                    onClick={() => handleEdit(message)}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    Editar
                    <span className="sr-only">-</span>
                  </button>
                  <button
                    onClick={() => handleDelete(message)}
                    className="ml-4 text-red-400 hover:text-indigo-900"
                  >
                    Excluir
                    <span className="sr-only">-</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  return renderRows();
}

QuickMessagesTable.propTypes = {
  messages: PropTypes.array.isRequired,
  showLoading: PropTypes.bool,
  editMessage: PropTypes.func.isRequired,
  deleteMessage: PropTypes.func.isRequired
};

export default QuickMessagesTable;
