import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  DialogContent,
  DialogActions,
  Grid
} from "@material-ui/core";
import PropType from "prop-types";
import Dialog from "../Dialog";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { i18n } from "../../translate/i18n";
import { makeStyles } from "@material-ui/core/styles";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { AuthContext } from "../../context/Auth/AuthContext";

import { isNil, isObject, has, get } from "lodash";

const MessageSchema = Yup.object().shape({
  shortcode: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  message: Yup.string()
    .min(3, "Too Short!")
    .max(500, "Too Long!")
    .required("Required")
});

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "350px"
    }
  },
  list: {
    width: "100%",
    maxWidth: "350px",
    maxHeight: "200px",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    width: "100%"
  }
}));

function QuickMessageDialog(props) {
  const classes = useStyles();

  const initialMessage = {
    id: null,
    shortcode: "",
    message: ""
  };

  const { modalOpen, saveMessage, editMessage, onClose, messageSelected } =
    props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState(initialMessage);
  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    verifyAndSetMessage();
    setDialogOpen(modalOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  useEffect(() => {
    verifyAndSetMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageSelected]);

  const messageSelectedIsValid = () => {
    return (
      isObject(messageSelected) &&
      has(messageSelected, "id") &&
      !isNil(get(messageSelected, "id"))
    );
  };

  const verifyAndSetMessage = () => {
    if (messageSelectedIsValid()) {
      const { id, message, shortcode } = messageSelected;
      setMessage({ id, message, shortcode });
    } else {
      setMessage(initialMessage);
    }
  };

  const handleClose = () => {
    onClose();
    setLoading(false);
  };

  const handleSave = async values => {
    if (messageSelectedIsValid()) {
      editMessage({
        ...messageSelected,
        ...values,
        userId: user.id
      });
    } else {
      saveMessage({
        ...values,
        userId: user.id
      });
    }
    handleClose();
  };

  return (
    <Dialog
      title="Mensagem Rápida"
      modalOpen={dialogOpen}
      onClose={handleClose}
    >
      <Formik
        initialValues={message}
        enableReinitialize={true}
        validationSchema={MessageSchema}
        onSubmit={(values, actions) => {
          setLoading(true);
          setTimeout(() => {
            handleSave(values);
            actions.setSubmitting(false);
          }, 400);
        }}
      >
        {({ touched, errors }) => (
          <Form>
            <DialogContent className={classes.root} dividers>
              <div className="flex flex-wrap mb-4">
                <div className="w-full p-2">
                  <label
                    htmlFor="shortcode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Atalho
                  </label>
                  <Field name="shortcode">
                    {({ field }) => (
                      <input
                        {...field}
                        type="shortcode"
                        className="w-full p-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      />
                    )}
                  </Field>
                </div>
                <div className="w-full p-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mensagem
                  </label>
                  <Field name="message">
                    {({ field }) => (
                      <textarea
                        {...field}
                        type="message"
                        rows={4}
                        className="w-full p-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      />
                    )}
                  </Field>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleClose}
                type="button"
                className="block rounded-md bg-red-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="block rounded-md bg-blue-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Salvar
              </button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

QuickMessageDialog.propType = {
  modalOpen: PropType.bool,
  onClose: PropType.func
};

export default QuickMessageDialog;
