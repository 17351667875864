import json2csv from "json2csv";

export function exportToCsv(data) {
  const csv = json2csv.parse(data);
  const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const currentDate = new Date().toLocaleDateString().replace(/\//g, "-"); // formatando a data e substituindo barras por hifens
  const csvUrl = window.URL.createObjectURL(csvBlob);
  const downloadLink = document.createElement("a");
  downloadLink.setAttribute("href", csvUrl);
  downloadLink.setAttribute("download", `making-dashboard-${currentDate}.csv`);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
