import React from "react";

import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";

const useStyles = makeStyles(theme => ({
  ticketHeader: {
    display: "flex",
    backgroundColor: "#eee",
    flex: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  }
}));

const TicketHeader = ({ loading, children }) => {
  const classes = useStyles();

  return (
    <>
      {loading ? (
        <TicketHeaderSkeleton />
      ) : (
        <div className="flex items-center bg-gray-100 border-t border-l border-r ">
          {children}
        </div>
      )}
    </>
  );
};

export default TicketHeader;
