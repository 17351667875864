import React, { useEffect, useState, useContext } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import {
  CheckCircle,
  CropFree,
  Facebook,
  Instagram,
  SignalCellular4Bar,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellularConnectedNoInternet2Bar,
  WhatsApp,
} from "@material-ui/icons";

import { blue, grey, green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import LineChart from "./LineChart";
import DepartamentChart from "./DepartamentChart";

import { isArray } from "lodash";

import useCompanies from "../../hooks/useCompanies";
import useDashboard from "../../hooks/useDashboard";

import { isEmpty } from "lodash";
import moment from "moment";
import { exportToCsv } from "../../components/ExportsFile/fileCsv";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import TagsChart from "./TagsChart";
import UserChart from "./UserChart";

import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: 240,
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [counters, setCounters] = useState({});
  const [params, setParams] = useState({});
  const [attendants, setAttendants] = useState([]);
  const [filterType, setFilterType] = useState(1);
  const [filterQueues, setFilterQueues] = useState("*");
  const [filterUsers, setFilterUsers] = useState("*");
  const [filterConnections, setFilterConnections] = useState("*");
  const [filterTags, setFilterTags] = useState("*");
  const [period, setPeriod] = useState(0);
  const [companyDueDate, setCompanyDueDate] = useState();
  const [dateFrom, setDateFrom] = useState(
    moment("1", "D").format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const [whatsapps, setWhatsapps] = useState([]);
  const [tags, setTags] = useState([]);
  const [queues, setQueues] = useState([]);
  const [users, setUsers] = useState([]);
  const { find } = useDashboard();
  const { finding } = useCompanies();

  const { whatsApps } = useContext(WhatsAppsContext);

  const loadTags = async () => {
    try {
      const { data } = await api.get(`/tags/list`);
      setTags(data);
    } catch (err) {
      toastError(err);
    }
  };

  function formatTime(minutes) {
    return moment()
      .startOf("day")
      .add(minutes, "minutes")
      .format("HH[h] mm[m]");
  }

  const CustomToolTip = ({ title, content, children }) => {
    const classes = useStyles();

    return (
      <Tooltip
        arrow
        classes={{
          tooltip: classes.tooltip,
          popper: classes.tooltipPopper,
        }}
        title={
          <React.Fragment>
            <Typography gutterBottom color="inherit">
              {title}
            </Typography>
            {content && <Typography>{content}</Typography>}
          </React.Fragment>
        }
      >
        {children}
      </Tooltip>
    );
  };

  const loadUsers = async () => {
    try {
      const { data } = await api.get("/users/");
      setUsers(data.users);
    } catch (err) {
      toastError(err);
    }
  };

  const loadQueues = async () => {
    try {
      const { data } = await api.get("/queue/list", {
        params: { companyId },
      });
      setQueues(data);
    } catch (err) {
      toastError(err);
    }
  };

  async function handleChangePeriod(value) {
    setPeriod(value);
  }

  async function fetchData() {
    setLoading(true);

    let params = {};

    if (period > 0) {
      params = {
        days: period,
      };
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = {
        ...params,
        date_from: moment(dateFrom).format("YYYY-MM-DD"),
      };
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = {
        ...params,
        date_to: moment(dateTo).format("YYYY-MM-DD"),
      };
    }

    if (filterUsers && filterUsers !== "*") {
      params = {
        ...params,
        user: filterUsers,
      };
    }

    if (filterConnections && filterConnections !== "*") {
      params = {
        ...params,
        connection: filterConnections,
      };
    }

    if (filterTags && filterTags !== "*") {
      params = {
        ...params,
        tag: filterTags,
      };
    }
    if (filterQueues && filterQueues !== "*") {
      params = {
        ...params,
        queue: filterQueues,
      };
    }

    if (Object.keys(params).length === 0) {
      toast.error("Parametrize o filtro");
      setLoading(false);
      return;
    }

    const data = await find(params);

    setCounters(data.counters);
    setParams(data.params);
    if (isArray(data.attendants)) {
      setAttendants(data.attendants);
    } else {
      setAttendants([]);
    }

    setLoading(false);
    setPanelOpen(false);
  }

  const companyId = localStorage.getItem("companyId");
  const loadCompanies = async () => {
    setLoading(true);
    try {
      const companiesList = await finding(companyId);
      setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
    } catch (e) {
      console.log("🚀 Console Log : e", e);
      // toast.error("Não foi possível carregar a lista de registros");
    }
    setLoading(false);
  };

  function renderFilters() {
    return (
      <>
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Data Inicial
          </label>
          <input
            type="date"
            value={dateFrom}
            onChange={(e) => setDateFrom(e.target.value)}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Data Final
          </label>
          <input
            label="Data Final"
            type="date"
            value={dateTo}
            onChange={(e) => setDateTo(e.target.value)}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </>
    );
  }

  function renderFiltersExtra() {
    return (
      <>
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Usuários
          </label>
          <select
            labelId="period-selector-label"
            value={filterUsers}
            onChange={(e) => setFilterUsers(e.target.value)}
            defaultChecked={filterUsers}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option value={"*"}></option>
            {users.map((user) => (
              <option value={user.id} key={user.id}>
                {user.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Conexões
          </label>
          <select
            labelId="period-selector-label"
            value={filterConnections}
            onChange={(e) => setFilterConnections(e.target.value)}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option value={"*"}></option>
            {whatsapps &&
              whatsapps.map((whatsapp) => (
                <option value={whatsapp.id} key={whatsapp.id}>
                  {whatsapp.name}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Tags
          </label>
          <select
            labelId="period-selector-label"
            value={filterTags}
            onChange={(e) => setFilterTags(e.target.value)}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option value={"*"}></option>
            {tags &&
              tags.map((tag) => (
                <option value={tag.id} key={tag.id}>
                  {tag.name}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Departamentos
          </label>
          <select
            labelId="period-selector-label"
            value={filterQueues}
            onChange={(e) => setFilterQueues(e.target.value)}
            defaultChecked={filterQueues}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <option value={"*"}></option>
            {queues &&
              queues.map((queue) => (
                <option value={queue.id} key={queue.id}>
                  {queue.name}
                </option>
              ))}
          </select>
        </div>
      </>
    );
  }

  useEffect(() => {
    async function firstLoad() {
      api
        .get(`/whatsapp`, { params: { companyId, session: 0 } })
        .then(({ data }) => setWhatsapps(data));
      api.get(`/queue/list`).then(({ data }) => setQueues(data));
      loadTags();
      loadUsers();
      await fetchData();
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      await loadCompanies();
    }
    fetchData();
  }, []);

  const [isPanelOpen, setPanelOpen] = useState(false);

  const togglePanel = () => {
    setPanelOpen(!isPanelOpen);
  };

  const renderStatusToolTips = (whatsApp) => {
    return (
      <div className={classes.customTableCell}>
        {whatsApp.status === "DISCONNECTED" && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.disconnected.title")}
            content={i18n.t("connections.toolTips.disconnected.content")}
          >
            <SignalCellularConnectedNoInternet0Bar color="secondary" />
          </CustomToolTip>
        )}
        {whatsApp.status === "OPENING" && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {whatsApp.status === "qrcode" && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.qrcode.title")}
            content={i18n.t("connections.toolTips.qrcode.content")}
          >
            <CropFree />
          </CustomToolTip>
        )}
        {whatsApp.status === "CONNECTED" && (
          <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
            <SignalCellular4Bar style={{ color: green[500] }} />
          </CustomToolTip>
        )}
        {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.timeout.title")}
            content={i18n.t("connections.toolTips.timeout.content")}
          >
            <SignalCellularConnectedNoInternet2Bar color="secondary" />
          </CustomToolTip>
        )}
      </div>
    );
  };

  return (
    <>
      {isPanelOpen && (
        <div
          className="relative z-50"
          aria-labelledby="slide-over-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <div className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <h2
                            className="text-base font-semibold leading-6 text-white"
                            id="slide-over-title"
                          >
                            Filtrar
                          </h2>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              onClick={togglePanel}
                              type="button"
                              className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            >
                              <span className="absolute -inset-2.5"></span>
                              <span className="sr-only">Close panel</span>
                              <svg
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pb-5 pt-6">
                            <div>
                              <label
                                for="project-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Project name
                              </label>
                              <div className="mt-2" />
                            </div>
                            {renderFiltersExtra()}
                            {renderFilters()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        onClick={togglePanel}
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Cancelar
                      </button>
                      <button
                        onClick={() => exportToCsv(counters)}
                        variant="contained"
                        className="ml-2 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Exportar CSV
                      </button>
                      <button
                        onClick={() => fetchData()}
                        type="submit"
                        className="ml-2 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Filtrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-20 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Dashboard
            </h1>
          </div>
          <div className="flex items-center mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              onClick={togglePanel}
              type="button"
              className="block rounded-md bg-indigo-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-4"
            >
              Filtros
            </button>
          </div>
        </div>
        <main>
          <div className="pt-6">
            <div className="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
              <div className="flex-2 bg-white shadow rounded-lg p-4 sm:p-8 xl:p-8 2xl:col-span-2">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex-shrink-0">
                    <span className="text-xl font-bold text-gray-900 mb-2">
                      Atendimentos nos últimos 7 dias
                    </span>
                  </div>
                  <div className="flex items-center justify-end flex-1 text-green-500 text-base font-bold"></div>
                </div>
                <LineChart data={counters.data} />
              </div>
              <div className="flex-1 bg-white shadow rounded-lg p-4 sm:p-4 xl:p-8">
                <div className="mb-4 flex items-center justify-between">
                  <div>
                    <h3 className="text-xl font-bold text-gray-900 mb-2">
                      Atendimentos
                    </h3>
                    <span className="text-base font-normal text-gray-500">
                      Resumo de atendimentos
                    </span>
                  </div>
                </div>

                <div className="flex flex-col mt-8">
                  <div className="overflow-x-auto rounded-lg">
                    <div className="align-middle inline-block min-w-full">
                      <div className="shadow overflow-hidden sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Valor
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            <tr>
                              <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                                Total
                              </td>
                              <td className="p-4 whitespace-nowrap text-sm font-semibold text-indigo-600">
                                {counters.total}
                              </td>
                            </tr>
                            <tr>
                              <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                                Pendentes
                              </td>
                              <td className="p-4 whitespace-nowrap text-sm font-semibold text-red-600">
                                {counters.pendentes}
                              </td>
                            </tr>
                            <tr>
                              <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                                Atendendo
                              </td>
                              <td className="p-4 whitespace-nowrap text-sm font-semibold text-indigo-600">
                                {counters.atendendo}
                              </td>
                            </tr>
                            <tr>
                              <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                                Finalizados
                              </td>
                              <td className="p-4 whitespace-nowrap text-sm font-semibold text-green-600">
                                {counters.finalizados}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
              <div className="flex-2 bg-white shadow rounded-lg p-4 sm:p-8 xl:p-8 2xl:col-span-2">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex-shrink-0">
                    <span className="text-xl font-bold text-gray-900 mb-2">
                      Conexões
                    </span>
                  </div>
                  <div className="flex items-center justify-end flex-1 text-green-500 text-base font-bold"></div>
                </div>
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Nome
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {whatsApps.map((whatsApp) => (
                      <tr key={whatsApp.id}>
                        <td className="text-left whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {whatsApp.name}
                        </td>
                        <td className="flex justify-start text-left whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {renderStatusToolTips(whatsApp)}
                        </td>
                       
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex-1">
                <div className="flex flex-col">
                  <div className="overflow-x-auto rounded-lg">
                    <div className="align-middle inline-block min-w-full">
                      <div className="shadow overflow-hidden sm:rounded-lg">
                        <div className="flex items-center bg-white shadow rounded-lg p-2 mb-4">
                          <div className="flex-shrink-0">
                            <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                              {formatTime(counters.tempoMedio)}
                            </span>
                            <h3 className="text-base font-normal text-gray-500">
                              Tempo médio de atendimento
                            </h3>
                          </div>
                        </div>
                        <div className="flex items-center bg-white shadow rounded-lg p-2">
                          <div className="flex-shrink-0">
                            <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                              {formatTime(counters.tempoMedioEspera)}
                            </span>
                            <h3 className="text-base font-normal text-gray-500">
                              Tempo médio de espera
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4  w-full grid grid-cols-1 xl:grid-cols-2 gap-4">
              <div className="flex-2 bg-white shadow rounded-lg p-4 sm:p-6 xl:p-6]">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex-shrink-0">
                    <span className="text-xl font-bold text-gray-900 mb-2">
                      Por departamentos
                    </span>
                  </div>
                  <div className="flex items-center justify-end flex-1 text-green-500 text-base font-bold"></div>
                </div>
                <DepartamentChart
                  key={JSON.stringify(counters.dataDepartamentos)}
                  data={counters.dataDepartamentos}
                />
              </div>
              <div className="flex-2 bg-white shadow rounded-lg p-4 sm:p-6 xl:p-6]">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex-shrink-0">
                    <span className="text-xl font-bold text-gray-900 mb-2">
                      Por Tags
                    </span>
                  </div>
                  <div className="flex items-center justify-end flex-1 text-green-500 text-base font-bold"></div>
                </div>
                <TagsChart
                  key={JSON.stringify(counters.dataTags)}
                  data={counters.dataTags}
                />
              </div>
              <div className="flex-2 bg-white shadow rounded-lg p-4 sm:p-6 xl:p-6]">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex-shrink-0">
                    <span className="text-xl font-bold text-gray-900 mb-2">
                      Por Usuários
                    </span>
                  </div>
                  <div className="flex items-center justify-end flex-1 text-green-500 text-base font-bold"></div>
                </div>
                <UserChart
                  key={JSON.stringify(counters.dataUsers)}
                  data={counters.dataUsers}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
