import React, { useState, useEffect } from "react";
import { makeStyles, TextField, Grid, Container } from "@material-ui/core";
import { Formik, Form, FastField, FieldArray } from "formik";
import { isArray } from "lodash";
import NumberFormat from "react-number-format";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  fullWidth: {
    width: "100%"
  },
  textfield: {
    width: "100%"
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  control: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  buttonContainer: {
    textAlign: "right",
    padding: theme.spacing(1)
  }
}));

function SchedulesForm(props) {
  const { initialValues, onSubmit, loading, labelSaveButton } = props;
  const classes = useStyles();

  const [schedules, setSchedules] = useState([
    {
      weekday: "Segunda-feira",
      weekdayEn: "monday",
      startTime: "",
      endTime: ""
    },
    {
      weekday: "Terça-feira",
      weekdayEn: "tuesday",
      startTime: "",
      endTime: ""
    },
    {
      weekday: "Quarta-feira",
      weekdayEn: "wednesday",
      startTime: "",
      endTime: ""
    },
    {
      weekday: "Quinta-feira",
      weekdayEn: "thursday",
      startTime: "",
      endTime: ""
    },
    { weekday: "Sexta-feira", weekdayEn: "friday", startTime: "", endTime: "" },
    { weekday: "Sábado", weekdayEn: "saturday", startTime: "", endTime: "" },
    { weekday: "Domingo", weekdayEn: "sunday", startTime: "", endTime: "" }
  ]);

  useEffect(() => {
    if (isArray(initialValues) && initialValues.length > 0) {
      setSchedules(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleSubmit = data => {
    onSubmit(data);
  };

  return (
    <Formik
      enableReinitialize
      className={classes.fullWidth}
      initialValues={{ schedules }}
      onSubmit={({ schedules }) =>
        setTimeout(() => {
          handleSubmit(schedules);
        }, 500)
      }
    >
      {({ values }) => (
        <Form className="w-full">
          <FieldArray name="schedules">
            {({ push, remove }) => (
              <div className="flex flex-wrap -m-2">
                {values.schedules.map((schedule, index) => (
                  <div className="w-full flex items-center p-2" key={index}>
                    <FastField name={`schedules.${index}.weekday`}>
                      {({ field }) => (
                        <input
                          {...field}
                          type="text"
                          disabled
                          className="px-3 py-2 mr-4 w-1/3 border rounded-md text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Dia da Semana"
                        />
                      )}
                    </FastField>

                    <FastField name={`schedules.${index}.startTime`}>
                      {({ field }) => (
                        <NumberFormat
                          {...field}
                          className="px-3 py-2 mr-4 w-1/3 border rounded-md text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Hora de Inicial"
                          format="##:##"
                        />
                      )}
                    </FastField>

                    <FastField name={`schedules.${index}.endTime`}>
                      {({ field }) => (
                        <NumberFormat
                          {...field}
                          className="px-3 py-2 mr-4 w-1/3 border rounded-md text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          placeholder="Hora de Final"
                          format="##:##"
                        />
                      )}
                    </FastField>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
          <div
            style={{ textAlign: "center", marginTop: "2%" }}
            className={classes.buttonContainer}
          >
            <button
              type="submit"
              className="block rounded-md bg-blue-600 px-2.5 py-2.5 text-center text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              {labelSaveButton ?? "Salvar"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SchedulesForm;
