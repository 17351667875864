import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../assets/logologin.png";

// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyleft "}
// 			<Link color="inherit" href="https://github.com/canove">
// 				Canove
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    height: "100vh",
    flexWrap: "wrap"
  },
  leftColumn: {
    flex: "0 0 60%",
    backgroundColor: "#121ade",
    position: "relative"
  },
  rightColumn: {
    flex: "0 0 40%",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  "@media (max-width: 860px)": {
    leftColumn: {
      flexBasis: "0%" // Oculta a coluna esquerda
    },
    rightColumn: {
      flexBasis: "100%" // Ocupa toda a largura disponível
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start", // Alterado para "flex-start"
    justifyContent: "flex-start" // Adicionado
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "" });

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = e => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <img
          src={require("../../assets/bg_img_1.png")}
          alt="Shape"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover"
          }}
        />
      </div>
      <div className={classes.rightColumn}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <div>
              <img
                style={{
                  margin: "0 auto",
                  height: "100%",
                  width: "100%",
                  alignSelf: "center"
                }}
                src={logo}
                alt="Whats"
              />
            </div>
            <Typography component="h3" variant="h6" style={{ width: "100%" }}>
              Olá, 👋
              <br />
              Bem-vindo à Making!
            </Typography>

            <form className="mt-4 space-y-4 w-full" onSubmit={handlSubmit}>
              <div className="relative w-full">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  {i18n.t("login.form.email")}
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={handleChangeInput}
                  autoComplete="email"
                  autoFocus
                  required
                  className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div className="relative w-full">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  {i18n.t("login.form.password")}
                </label>
                <input
                  id="password"
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={handleChangeInput}
                  autoComplete="current-password"
                  required
                  className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <button
                type="submit"
                className="w-full py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-md"
              >
                {i18n.t("login.buttons.submit")}
              </button>

              <div className="flex mt-4">
                <div className="flex-1 text-sm">
                  <a
                    href="https://makingpublicidade.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-600 hover:text-indigo-800"
                  >
                    {i18n.t("login.buttons.register")}
                  </a>
                </div>
              </div>
            </form>
          </div>
          <Box mt={8}>{/* <Copyright /> */}</Box>
        </Container>
      </div>
    </div>
  );
};

export default Login;
