import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
  chip: {
    margin: 2
  }
}));

const QueueSelectMultiple = ({ selectedQueueId, onChange }) => {
  const classes = useStyles();
  const [queues, setQueues] = useState([]);
  const [selectedQueue, setSelectedQueue] = useState([]);

  useEffect(() => {
    setSelectedQueue(selectedQueueId);
  }, [selectedQueueId]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const handleChange = e => {
    const selected = Array.from(e.target.options)
      .filter(option => option.selected)
      .map(option => option.value);
      
    setSelectedQueue(selected);
    onChange(selected);
  };

  return (
    <div className="w-full p-2">
      <label className="block text-sm font-medium text-gray-700">
        {i18n.t("queueSelect.inputLabel")}
      </label>
      <select
        multiple={true}
        value={selectedQueue}
        onChange={handleChange}
        className="w-full p-2 text-base border bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        {queues.map(queue => (
          <option key={queue.id} value={queue.id}>
            {queue.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default QueueSelectMultiple;
