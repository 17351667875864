import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import BlackList from "../pages/BlackList";
import Connections from "../pages/Connections/";
import ContactListItems from "../pages/ContactListItems/";
import ContactLists from "../pages/ContactLists/";
import Contacts from "../pages/Contacts/";
import Dashboard from "../pages/Dashboard/";
import Financeiro from "../pages/Financeiro/";
import Helps from "../pages/Helps/";
import Login from "../pages/Login/";
import MessagesAPI from "../pages/MessagesAPI/";
import Queues from "../pages/Queues/";
import SettingsCustom from "../pages/SettingsCustom/";
import Signup from "../pages/Signup/";
import Tags from "../pages/Tags/";
import TicketResponsiveContainer from "../pages/TicketResponsiveContainer";
import Users from "../pages/Users";
// import Companies from "../pages/Companies/";
import { AuthProvider } from "../context/Auth/AuthContext";
import { TicketsContextProvider } from "../context/Tickets/TicketsContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Annoucements from "../pages/Annoucements";
import CampaignReport from "../pages/CampaignReport";
import Campaigns from "../pages/Campaigns";
import CampaignsConfig from "../pages/CampaignsConfig";
import Chat from "../pages/Chat";
import QuickMessages from "../pages/QuickMessages/";
import Schedules from "../pages/Schedules";
import Subscription from "../pages/Subscription/";
import Route from "./Route";

const Routes = () => {
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showTicket, setShowTicket] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showSheduled, setShowSheduled] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showBlockList, setShowBlockList] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showUser, setShowUsers] = useState(false);
  const [showQueu, setShowQueu] = useState(false);
  const [connections, setConnections] = useState(false);
  const [quickMessages, setQuickMessages] = useState(false);
  const [helps, setHelps] = useState(false);
  const [messagesApi, setMessagesApi] = useState(false);
  const [settings, setSettings] = useState(false);
  const [financeiro, setFinanceiro] = useState(false);
  const [announcements, setAnnouncements] = useState(false);
  const [subscription, setSubscription] = useState(false);

  useEffect(() => {
    const cshow = localStorage.getItem("cshow");
    const adminshow = localStorage.getItem("adminshow");
    const operationshow = localStorage.getItem("operationshow");
    const supervisorshow = localStorage.getItem("supervisorshow");
    const managershow = localStorage.getItem("managershow");
    const admshow = localStorage.getItem("admShow");
    if (cshow !== undefined) {
      setShowCampaigns(true);
    }
    if (operationshow) {
      setShowTicket(true);
      setShowContact(true);
      setShowSheduled(true);
      setShowChat(true);
      setQuickMessages(true);
    }
    if (supervisorshow) {
      setShowTicket(true);
      setShowContact(true);
      setShowSheduled(true);
      setShowChat(true);
      setShowBlockList(true);
      setShowTags(true);
    }
    if (managershow) {
      setShowTicket(true);
      setShowContact(true);
      setShowSheduled(true);
      setShowChat(true);
      setShowBlockList(true);
      setShowTags(true);
      setShowUsers(true);
      setShowQueu(true);
    }
    if (admshow) {
      setShowTicket(true);
      setShowContact(true);
      setShowSheduled(true);
      setShowChat(true);
      setShowBlockList(true);
      setShowTags(true);
      setShowUsers(true);
      setShowQueu(true);
      setConnections(true);
      setQuickMessages(true);
      setHelps(true);
      setMessagesApi(true);
      setSettings(true);
      setFinanceiro(false);
      setAnnouncements(true);
      setSubscription(false);
    }
    
    if (adminshow) {
      setShowTicket(true);
      setShowContact(true);
      setShowSheduled(true);
      setShowChat(true);
      setShowBlockList(true);
      setShowTags(true);
      setShowUsers(true);
      setShowQueu(true);
      setConnections(true);
      setQuickMessages(true);
      setHelps(true);
      setMessagesApi(true);
      setSettings(true);
      setFinanceiro(true);
      setAnnouncements(true);
      setSubscription(true);
    }

    if (!adminshow && !managershow && !managershow && !supervisorshow && !operationshow) {
      setShowTicket(true);
      setShowContact(true);
      setShowSheduled(true);
      setShowChat(true);
      setQuickMessages(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <TicketsContextProvider>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            {/* <Route exact path="/create-company" component={Companies} /> */}
            <WhatsAppsProvider>
              <LoggedInLayout>
                <Route exact path="/" component={Dashboard} isPrivate />
                {showTicket && (
                  <Route
                    exact
                    path="/tickets/:ticketId?"
                    component={TicketResponsiveContainer}
                    isPrivate
                  />
                )}
                {connections && (
                  <Route
                    exact
                    path="/connections"
                    component={Connections}
                    isPrivate
                  />
                )}
                {quickMessages && (
                  <Route
                    exact
                    path="/quick-messages"
                    component={QuickMessages}
                    isPrivate
                  />
                )}
                {showSheduled && (
                  <Route
                    exact
                    path="/schedules"
                    component={Schedules}
                    isPrivate
                  />
                )}
                {showTags && (
                  <Route exact path="/tags" component={Tags} isPrivate />
                )}
                {showBlockList && (
                  <Route
                    exact
                    path="/blacklist"
                    component={BlackList}
                    isPrivate
                  />
                )}
                {showContact && (
                  <Route
                    exact
                    path="/contacts"
                    component={Contacts}
                    isPrivate
                  />
                )}
                {helps && (
                  <Route exact path="/helps" component={Helps} isPrivate />
                )}
                {showUser && (
                  <Route exact path="/users" component={Users} isPrivate />
                )}
                {messagesApi && (
                  <Route
                    exact
                    path="/messages-api"
                    component={MessagesAPI}
                    isPrivate
                  />
                )}
                {settings && (
                  <Route
                    exact
                    path="/settings"
                    component={SettingsCustom}
                    isPrivate
                  />
                )}
                {financeiro && (
                  <Route
                    exact
                    path="/financeiro"
                    component={Financeiro}
                    isPrivate
                  />
                )}
                {showQueu && (
                  <Route exact path="/queues" component={Queues} isPrivate />
                )}
                {announcements && (
                  <Route
                    exact
                    path="/announcements"
                    component={Annoucements}
                    isPrivate
                  />
                )}
                {subscription && (
                  <Route
                    exact
                    path="/subscription"
                    component={Subscription}
                    isPrivate
                  />
                )}
                {showChat && (
                  <Route exact path="/chats/:id?" component={Chat} isPrivate />
                )}
                {showCampaigns && (
                  <>
                    <Route
                      exact
                      path="/contact-lists"
                      component={ContactLists}
                      isPrivate
                    />
                    <Route
                      exact
                      path="/contact-lists/:contactListId/contacts"
                      component={ContactListItems}
                      isPrivate
                    />
                    <Route
                      exact
                      path="/campaigns"
                      component={Campaigns}
                      isPrivate
                    />
                    <Route
                      exact
                      path="/campaign/:campaignId/report"
                      component={CampaignReport}
                      isPrivate
                    />
                    <Route
                      exact
                      path="/campaigns-config"
                      component={CampaignsConfig}
                      isPrivate
                    />
                  </>
                )}
              </LoggedInLayout>
            </WhatsAppsProvider>
          </Switch>
          <ToastContainer autoClose={3000} />
        </TicketsContextProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
