export const profilesGlobal = [
  {
    name: "Operador",
    value: "attendant"
  },
  {
    name: "Supervisor",
    value: "supervisor"
  },
  {
    name: "Gerente",
    value: "manager"
  },
  {
    name: "Administrador",
    value: "adm"
  },
  {
    name: "Super Admin",
    value: "admin"
  }
];
