import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";

const LineChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data && data.length > 0) {
      renderChart();
    }
  }, [data]);


  const renderChart = () => {
    const options = {
      chart: {
        type: "line",
        height: "300px",
      },
      series: [
        {
          name: "Total Atendimentos",
          data: data.map(item => item.totalAtendimentos)
        }
      ],
      xaxis: {
        categories: data.map(item => item.date)
      }
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();
  };

  return <div ref={chartRef} className="w-full" />;
};

export default LineChart;
